













































































































































import Vue from "vue";
import { Auth } from "aws-amplify";
import { DetailItem, ManualControlData } from "@/types";
import ManualControlDialog from "../../components/ManualControlDialog.vue";
import WebApiModel from "@/apis/web_api";
import { getManualControlData } from "@/lib/web_api_util";
import store from "@/store";
import { debugLog } from "@/lib/debug_util";

export default Vue.extend({
  name: "custom-list",
  components: { ManualControlDialog },
  props: {
    dataList: { type: Array, default: () => [] },
    isMobile: { type: Boolean, default: false },
  },
  data() {
    return {
      okColor: "#58AC31",
      showManualControlDialog: false,
      controlAuthority: false,
      detailItem: {} as DetailItem,
      webApiModel: {} as WebApiModel,
      // ローディング表示制御
      isLoading: false,
      // エラーダイアログ表示制御
      showErrorDialog: false,
      // エラーダイアログメッセージ
      errorDialogMessage: "",
      // 手動制御関連状況
      manualControlData: {} as ManualControlData,
    };
  },
  created() {
    this.getControlAuthority();
    // APIアクセス用ライブラリを作成
    this.webApiModel = new WebApiModel();
  },
  computed: {
    isAutoUpdate() {
      return store.getters.isAutoUpdate;
    },
  },
  methods: {
    getIconColor(data: DetailItem) {
      if (data.onoff && data.value === true) {
        return data.colorOn;
      } else if (data.onoff && data.value === false) {
        return data.colorOff;
      } else {
        return "#58AC31";
      }
    },
    isManualControlDisabled(data: DetailItem) {
      console.log(data);
      // 非常停止中の場合
      const isEmergencyStop = store.getters.isEmergencyStop;
      if (isEmergencyStop) {
        // 非常停止の手動制御は可能とする
        if (data.plcAddress === "M_EMERGENCY_STOP_OUTPUT") {
          return false;
        }
        // それ以外は手動制御は不可とする
        else {
          return true;
        }
      }
      // 自動運転中の場合
      const isAutomation = store.getters.isAutomation;
      if (isAutomation) {
        // 自動運転と非常停止の手動制御は可能とする
        if (
          data.plcAddress === "M_AUTOMATION_OUTPUT" ||
          data.plcAddress === "M_EMERGENCY_STOP_OUTPUT"
        ) {
          return false;
        }
        // それ以外は手動制御は不可とする
        return true;
      }
      return false;
    },
    async openManualControlDialog(data: DetailItem): Promise<void> {
      const result = await this.getManualControlData(data.plcAddress);
      if (!result) {
        return;
      }
      this.detailItem = Object.assign({}, data);
      this.showManualControlDialog = true;
    },
    closeManualControlDialog() {
      this.showManualControlDialog = false;
    },
    async getControlAuthority() {
      const user = await Auth.currentAuthenticatedUser();
      const controlAuthority = user.attributes["custom:control_authority"];
      if (controlAuthority === "true") {
        this.controlAuthority = true;
      }
    },

    // MORATGW制御管理情報取得
    async getManualControlData(equipmentId: string): Promise<boolean> {
      // ローディング表示ON
      this.isLoading = true;
      // パラメータ表示
      debugLog("equipmentId", equipmentId);
      //MORATGW制御管理情報取得APIを呼び出す
      const response = await getManualControlData(
        this.webApiModel,
        store.getters.companies_plant.imsi,
        equipmentId,
        this.manualControlData
      );
      // エラーの場合は中断する
      if (response === undefined) {
        // ローディング表示OFF
        this.isLoading = false;
        // エラーポップアップを表示する
        this.errorDialogMessage = "MORATGW制御管理情報の取得に失敗しました。";
        this.showErrorDialog = true;
        return false;
      }
      // ローディング表示OFF
      this.isLoading = false;
      return true;
    },

    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
