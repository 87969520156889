



















































































































import Vue, { PropType } from "vue";
import Score from "./Score.vue";
import store from "@/store";
import AlertRecommendDialog from "../components/AlertRecommendDialog.vue";
import { AlertsInfo, AlertRecommend, Scores } from "@/types";
import { debugLog } from "@/lib/debug_util";
import WebApiModel from "@/apis/web_api";
import { getPlantAlertRecommend } from "@/lib/web_api_util";
export default Vue.extend({
  name: "navigation-drawer",
  components: { AlertRecommendDialog, Score },
  data() {
    return {
      webApiModel: {} as WebApiModel,
      mini: false,
      isVisibleAlertRecommendDialog: false,
      alertMessage: "",
      alertRecommend: {} as AlertRecommend,
      // ローディング表示用
      isLoadingDisplay: false,
      // エラーポップアップ表示関連
      showErrorDialog: false,
      errorDialogMessage: "",
      errorDialogType: "",
    };
  },
  props: {
    alertItems: { type: Array as PropType<AlertsInfo[]>, default: () => [] },
    scores: { type: Array as PropType<Scores[]>, default: () => [] },
  },
  created: function () {
    this.webApiModel = new WebApiModel();
  },
  computed: {
    navigationWidth() {
      if (
        window.matchMedia("(min-width: 960px) and (max-width: 1264px)").matches
      ) {
        return 260;
      } else {
        return 300;
      }
    },
    alertNumber() {
      return (this.alertItems as AlertsInfo[]).length;
    },
  },
  methods: {
    // アラートアイテムをクリックした時
    // （クリックできないようにされているため動作しない）
    async clickAlertItem(item: AlertsInfo, index: number): Promise<void> {
      debugLog("click alert", "index=" + index, item.alert_message);

      // アラートレコメンド追加前の過去データを表示している場合、plc_addressは空である
      // その場合はアラートレコメンドを取得できないため、
      // メッセージポップアップを表示し処理を中断する
      if (item.plc_address == null || item.plc_address.length === 0) {
        // エラーポップアップを表示する
        this.errorDialogType = "info";
        this.errorDialogMessage = "この時刻のアラート詳細は登録されていません";
        this.showErrorDialog = true;
        return;
      }

      // ローディング表示オン
      this.isLoadingDisplay = true;
      // IMSIとPLCアドレスを指定してアラートレコメンドを取得する
      const imsi = store.getters.companies_plant.imsi;
      const response = await getPlantAlertRecommend(
        this.webApiModel,
        imsi,
        item.plc_address
      );

      // エラーの場合はエラーポップアップを表示し処理を中断する
      if (response === undefined) {
        // エラーポップアップを表示する
        this.errorDialogType = "error";
        this.errorDialogMessage =
          "プラントアラートレコメンドの取得に失敗しました";
        this.showErrorDialog = true;
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        return;
      }
      // データがない場合はエラーポップアップを表示し処理を中断する
      else if (
        response.records === undefined ||
        response.records.length === 0
      ) {
        // エラーポップアップを表示する
        this.errorDialogType = "info";
        this.errorDialogMessage = "アラート詳細が登録されていません";
        this.showErrorDialog = true;
        // ローディング表示オフ
        this.isLoadingDisplay = false;
        return;
      }

      // アラート詳細ダイアログを表示する
      this.alertMessage = item.alert_message;
      this.alertRecommend = response.records[0];
      this.isVisibleAlertRecommendDialog = true;
      // ローディング表示オフ
      this.isLoadingDisplay = false;
    },

    // アラート詳細ダイアログを閉じる
    responseAlertRecommendDialog() {
      this.isVisibleAlertRecommendDialog = false;
    },

    // エラーポップアップを閉じる
    resetErrorDialog(): void {
      this.errorDialogMessage = "";
      this.showErrorDialog = false;
    },
  },
});
